import React from 'react';
import { useActionData } from "react-router-dom";

function ThankYou() {
  // let response = useActionData()
  let response = { name: 'lee' }
console.log(response)
  return (
    response ? (
      <div>
        <h2>{response.name} Thank you for completing our form</h2>
        {/* Your other JSX elements and components can go here */}
      </div>
    ) : (
      <div>thanks for nothing</div>
    )
  );
  
}

export default ThankYou;

