import React from "react";

function RoboText({ index, children }) {
    const dynamicClassName = `robo-text robo-text-${index || 0}`
    console.log(dynamicClassName)
    return (
        <p className={dynamicClassName}> {children} </p>
  );
}

export default RoboText;
