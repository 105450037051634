import './EntryForm.css';
import { Form } from "react-router-dom";
import RoboText from './RoboText';

function EntryForm() {
    return (
        <Form method="post" action="/thankyou">
            <input type="text" name="name" />
            <input type="text" name="lifestory" />
            <input type="text" name="age" />
            <input type="text" name="address" />
            <input type="text" name="tickets" />

            <button type="submit">SUBMIT</button>
        </Form>
  );
}

export default EntryForm;
