import React from 'react'
import EntryForm from './EntryForm'
import RoboText from './RoboText'
import AiChat from './AiChat'
import './PrivacyBot.css'

function PrivacyBot() {

    return (
        <main class="privacy-bot">
            <AiChat>
                <RoboText index="0">
                    Hi, I'm Privacy Policy Bot, here to help you generate a comprehensive and personalized privacy policy for your website or application.
                </RoboText>

                <RoboText index="1">
                  Crafting a privacy policy is essential to inform your users about how you collect, use, and protect their personal data. 
                </RoboText>
                <RoboText index="2">                
                  With my assistance, you can rest assured that your privacy policy will comply with relevant laws and regulations, ensuring transparency and trust between you and your users.
                </RoboText>
                <RoboText index="3">
                    To get started, simply provide me with some basic information about your business and the application. 
                </RoboText>
                <RoboText index="4">
                    I'll guide you through a series of questions designed to cover all the necessary aspects of your privacy policy. 
                </RoboText>
                <RoboText index="5">                                    
                    At the end you will be able to customize the policy based on your specific needs and industry practices.
                </RoboText>
                <RoboText index="1">
                    First Question: What is your app's name?
                </RoboText>
            </AiChat>
            <EntryForm />
        </main>
    )
}

export default PrivacyBot

