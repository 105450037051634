import React from "react";

function AiChat({ children }) {
    return (
        <article class="chat">
            {children}
        </article>
    )
}

export default AiChat